import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// 通用字体
import 'vfonts/Lato.css'
// 等宽字体
import 'vfonts/FiraCode.css'

// import {
//   // component
//   NButton,

//   // create naive ui
//   create
// } from 'naive-ui'

// const naive = create({
//   components: [NButton]
// })

import naive from 'naive-ui'


router.beforeEach((to,form,next)=>{
  if(to.meta.title){
      document.title=to.meta.title;
  }
  next();
})

createApp(App)
.use(router)
.use(naive)
.mount('#app');


