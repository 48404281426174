<template>
  <n-space vertical>
    <n-layout>
      <!-- <n-layout-header :inverted="inverted" bordered>
      </n-layout-header> -->
      <n-layout :style="{ 'height': bodyHeight + 'px' }" has-sider>
        <n-layout-sider bordered collapse-mode="width" :collapsed-width="1" :collapsed="collapsed" show-trigger
          style="background-color: #F5F7F9" @collapse="collapsed = true" @expand="collapsed = false">
          <h2 style="margin-left: 30px;"></h2>
          <div>
            <n-menu :options="menuOptions" :collapsed="collapsed" :collapsed-width="64" :collapsed-icon-size="22"
              style="margin-top: 40px;margin-left: 5px;margin-right: 10px; font-weight: bold;background-color: #F6F7FA; font-size: large;" />
          </div>

        </n-layout-sider>
        <n-layout-content style="margin-top: 20px;">
          <router-view />
        </n-layout-content>
      </n-layout>
      <!-- <n-layout-footer :inverted="inverted" bordered>
       
      </n-layout-footer> -->
    </n-layout>
  </n-space>
</template>

<script>
import { defineComponent, h, ref } from "vue";
import { RouterLink } from "vue-router";
import { NIcon } from "naive-ui";
import {
  BookOutline
} from "@vicons/ionicons5";

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

const menuOptions = [
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "home",
          params: {
            lang: "zh-CN"
          }
        }
      },
      { default: () => "主页" }
    ),
    key: "home",
    icon: renderIcon(BookOutline)
  }
  // {
  //   label: () => h(
  //     RouterLink,
  //     {
  //       to: {
  //         name: "life",
  //       }
  //     },
  //     { default: () => "生活" }
  //   ),
  //   key: "life",
  //   icon: renderIcon(StorefrontOutline)
  // },
  // {
  //   label: () => h(
  //     RouterLink,
  //     {
  //       to: {
  //         name: "program",
  //         params: {
  //           lang: "zh-CN"
  //         }
  //       }
  //     },
  //     { default: () => "编程" }
  //   ),
  //   key: "program",
  //   icon:renderIcon(BuildOutline)
  // }
];

export default defineComponent({
  setup() {
    return {
      inverted: ref(false),
      menuOptions
    };
  },
  data() {
    return {
      bodyHeight: 0,
      screenWidth: 0,
      screenHeight: 0,
      collapsed: false
    }
  },
  mounted() {
    this.bodyHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 500) {
      this.collapsed = true;
    }
    // 获取当前页面的完整 URL
    const currentHref = window.location.href;

    // 找到查询字符串的开始位置
    const queryStringIndex = currentHref.indexOf('?');

    // 如果存在查询字符串
    if (queryStringIndex !== -1) {
      // 提取查询字符串
      const queryString = currentHref.substring(queryStringIndex + 1);

      // 分割查询字符串为键值对
      const params = queryString.split('&');

      // 创建一个对象来存储查询参数
      const paramsObj = {};

      // 解析每个键值对
      params.forEach(param => {
        const [key, value] = param.split('=');
        paramsObj[key] = value ? decodeURIComponent(value) : '';
      });

      const n = paramsObj['n'];
      console.log(n, "params");
      if (n === "1") {
        this.collapsed = true;
      }
    }
  }
});
</script>
